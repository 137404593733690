.Loading {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Loading h1 {
    font-size: 60px;
    text-shadow: 0px 2px 2px #00000040;
}