.Flare {
    width: 100%;
    display: flex;
}

.bar {
    height: 7.5px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px #00000040;
}

.purple.bar {
    animation: purple-load .5s forwards .5s;
}

.blue.bar {
    animation: blue-load .5s forwards 1s;

}

.pink.bar {
    animation: pink-load .5s forwards 1.5s;

}

.inline {
    padding: 0 20px;
    gap: 5px;
}

.center {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.center .purple.bar{
    animation: purple-load .5s forwards .5s, shuffle .5s forwards 2.5s;
}

.center .blue.bar{
    animation: blue-load .5s forwards 1s, shuffle .5s forwards 2.75s
}

.center .pink.bar{
    animation: pink-load .5s forwards 1.5s, shuffle .5s forwards 3s;
}

.left {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}


.right {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    max-width: 200px;
}

.upward {
    height: 200px;
    flex-direction: row;
    align-items: flex-end;
    gap: 15px;
}

.loading .purple.bar{
    width: 100%;
    animation: shuffle 1s infinite;
}

.loading .blue.bar{
    width: 100%;
    animation: shuffle 1s infinite reverse;
}

.loading .pink.bar{
    width: 100%;
    animation: shuffle 1s infinite;
}

.Flare.upward {
    width: 186px !important;
}

.upward .bar {
    width: 10px;
}

.upward .purple.bar {
    animation: purple-height-load .5s forwards .5s;

}

.upward .blue.bar {
    animation: blue-height-load .5s forwards 1s;

}

.upward .pink.bar {
    animation: pink-height-load .5s forwards 1.5s;

}

@keyframes purple-load {
    0% {
        width: 0;
    }
    50%{
        width: 110%;
    }
    100% {
        width: 100%;
    }
}

@keyframes blue-load {
    0% {
        width: 0;
    }
    50%{
        width: 55%;
    }
    100% {
        width: 50%;
    }
}

@keyframes pink-load {
    0% {
        width: 0;
    }
    50%{
        width: 27.5%;
    }
    100% {
        width: 25%;
    }
}

@keyframes purple-height-load {
    0% {
        height: 10px;
    }
    50%{
        height: 110%;
    }
    100% {
        height: 100%;
    }
}

@keyframes blue-height-load {
    0% {
        height: 10px;
    }
    50%{
        height: 55%;
    }
    100% {
        height: 50%;
    }
}

@keyframes pink-height-load {
    0% {
        height: 10px;
    }
    50%{
        height: 27.5%;
    }
    100% {
        height: 25%;
    }
}

@keyframes shuffle {
    0%{
        transform: translateY(0);
    }   
    33%{
        transform: translateY(-30%);
    }
    66%{
        transform: translateY(15%);
    }
    100%{
        transform: translateY(0);
    }
}

@media only screen and (min-width: 600px) {
    .bar {
        height: 10px;
    }
    
    .right {
        max-width: 250px;
        gap: 15px;
    }
}