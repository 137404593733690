.Contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
}

.Contact h2 {
    font-size: 50px;
    margin: 0;
    text-shadow: 0px 2px 2px #00000040;
}

.Contact .Flare.upward {
    display: none;
}

.Contact .Flare.inline {
    display: flex;
    margin-top: 10px;
}

.contact-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.hello-there {
    background-color: #ffffffda;
    margin-bottom: 20px;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0px 2px 2px #00000040;
}

.hello-there-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.hello-there h3 {
    font-size: 32px;
    margin: 0;
}

.hello-there p {
    font-size: 20px;
    margin: 0;
}

.wave {
    animation: wave-animation 3s infinite 2s;
}

.contact-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 10px;
}

.contact-link-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0 30px 0;
    gap: 40px;
}

.contact-link {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    opacity: 0;
    border-radius: 10px;
    box-shadow: 0px 3px 3px #00000040;
}

.contact-link h4 {
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    color: #fff;
    border-radius: 10px 0 0 10px;
    text-shadow: 0px 2px 2px #00000040;
}

.contact-link p {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding-left: 20px;
    border-radius: 0 10px 10px 0;
    background-color: #ffffffda;
    text-shadow: 0px 1px 1px #00000040;
}


@media only screen and (min-width: 360px) {
    .contact-link {
        font-size: 18px
    }
}

@media only screen and (min-width: 460px) {
    .hello-there {
        text-align: justify;
    }

    .hello-there-title {
        justify-content: flex-start;
    }
}

@media only screen and (min-width: 570px) {
    .contact-info {
        padding: 30px;
    }
}

@media only screen and (min-width: 620px) {
    .contact-link h4 {
        width: 200px;
    }
}

@media only screen and (min-width: 740px) {
    .contact-link {
        font-size: 22px;
    }
}

@media only screen and (min-width: 950px) {
    .Contact {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
    }

    .Contact .Flare.upward {
        display: flex;
    }

    .Contact .Flare.inline {
        display: none;
    }

    .hello-there{
        background-color: transparent;
        padding: none;
        border-radius: none;
        box-shadow: none;
    }

    .contact-sidebar {
        height: 100%;
        width: 230px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
    }

    .contact-info {
        width: 75%;
        overflow-y: scroll;
        background-color: #ffffffda;
        padding: 10px;
        border-radius: 15px;
        box-shadow: 0px 2px 2px #00000040;
        padding: 30px;
    }

    .contact-link-list{
        padding: 10px 0;

    }

    .contact-link{
        box-shadow: none;
        padding: 0 20px;
    }

    .contact-link h4 {
        box-shadow: none;
    }

    .contact-link p {
        background-image: linear-gradient(135deg, #5ebcff40, #5e81ff40, #5ebcff40);
        box-shadow: none;
    }
}

@keyframes wave-animation {
    0% {
        transform: rotate(0deg)
    }

    25% {
        transform: rotate(20deg)
    }

    50% {
        transform: rotate(-10deg)
    }

    75% {
        transform: rotate(20deg)
    }

    100% {
        transform: rotate(0deg)
    }
}