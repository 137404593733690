.project {
    width: 300px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffffda;
    border-radius: 12.5px;
    box-shadow: 0px 2px 2px #00000030;
    margin-bottom: 10px;
}

.project-img {
    width: 300px;
}

.project p {
    padding: 0 20px;
    font-size: 20px;
    line-height: 1.5;
}

.project-title {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    margin: 10px 0 0 0;

}

.project-title h3 {
    font-size: 35px;
    margin: 0;
}

.project-title p {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
}

.project-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.project-tools {
    color: #fff;
    margin: 0 20px;
    padding: 10px 15px !important;
    background-color: #5ebcffbd;
    border-radius: 15px;
    text-align: justify;
}

.project-description {
    height: auto;
    text-align: center;
    margin: 0;
}

.project-bottom {
    padding-bottom: 20px;
}

.project-buttons {
    width: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.project-buttons a {
    flex: 1;
    display: flex;
    text-decoration: none;
}

.project-buttons button {
    flex: 1;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    border: none;
    color: #fff;
    box-shadow: 0px 2px 2px #00000030;
    border-radius: 10px;
    gap: 10px;
    font-weight: bold;
}

.project-buttons img {
    width: 30px;
    height: auto;
}

@media only screen and (min-width: 360px) {
    .project {
        width: 320px;
        margin-bottom: 30px;
    }

    .project-img {
        width: 320px;
    }

    .project-description {
        text-align: justify;
    }

    .project-buttons button {
        width: 140px;
    }
}

@media only screen and (min-width: 450px) {
    .project {
        width: 400px;
    }

    .project-img {
        width: 400px;
    }

    .project-buttons button {
        width: 140px;
        height: 65px;
        font-size: 26px;
    }

    .project-buttons img {
        width: 30px;
    }

    .project-buttons {
        gap: 30px
    }
}

@media only screen and (min-width: 600px) {
    .project {
        width: 500px;
    }

    .project-img {
        width: 500px;
    }

    .project-buttons button {
        width: 180px;
        height: 75px;
        font-size: 30px;
    }

    .project-buttons img {
        width: 40px;
    }
}

@media only screen and (min-width: 1160px) {
    .project-info {
        height: 450px;
    }
}