@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.Home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Home h1 {
    width: 280px;
    font-size: 40px;
    color: #5e81ff;
    margin: 10px 0px;
    text-shadow: 0px 2px 2px #00000040;
}

.Home h2 {
    flex: 1;
    background-image: linear-gradient(135deg, #5ebcff, #5e81ff, #5ebcff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin: 10px 0;
    padding-left: 40px;
}

.fake-search-bar {
    width: 280px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 2px #00000040;
    animation: rise 1s forwards 2s;
    transform: translateY(50px);
    opacity: 0;
}

.fake-search {
    width: 20px;
    height: 20px;
    margin: 5px 20px 0 0;
    animation: rotate 1s forwards 2.5s;
    opacity: 40%;
}

@media only screen and (min-width: 360px) {
    .Home h1 {
        width: 320px;
        font-size: 45px;
    }

    .Home h2 {
        font-size: 20px;
    }

    .fake-search-bar {
        width: 320px;
    }
}

@media only screen and (min-width: 412px) {
    .Home h1 {
        width: 340px;
        font-size: 45px;
    }

    .Home h2 {
        font-size: 22px;
    }

    .fake-search-bar {
        width: 340px;
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 500px) {
    .Home h1 {
        font-size: 55px;
        width: 402px;
    }

    .Home h2 {
        font-size: 25px;
    }

    .fake-search-bar {
        width: 402px;
        margin-bottom: 50px;
    }

}

@media only screen and (min-width: 650px) {
    .Home h1 {
        font-size: 75px;
        width: 535px;
    }

    .Home h2 {
        font-size: 30px;
        padding-left: 50px;
    }

    .fake-search-bar {
        width: 535px;
    }

    .fake-search {
        width: 30px;
        height: 30px;
    }
}

@media only screen and (min-width: 810px) {
    .Home h1 {
        font-size: 90px;
        width: 670px;
    }

    .Home h2 {
        font-size: 40px;
        padding-left: 60px;
    }

    .fake-search-bar {
        width: 640px;
    }

    .fake-search {
        width: 40px;
        height: 40px;
    }
}