@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

footer {
    grid-column: 2 / span 48;
    grid-row: 49 / span 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin-top: 20px;
    color: #fff;
    background-color: transparent;
}

footer>p {
    margin: 0;
    text-align: center;
}

footer a {
    color: inherit;
}

footer:hover a{
    text-decoration: underline;
}