
.Projects {
    width: 100%;
}

.project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffffda;
    box-shadow: 0px 2px 2px #00000030;
    margin: 20px 40px;
    padding: 20px 40px;
    gap: 10px;
    border-radius: 15px;
}

.project-header h2 {
    font-size: 40px;
    margin: 0;
    text-shadow: 0px 2px 2px #00000040;
}

.feed {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    gap: 40px;
    flex-wrap: wrap;
}

@media only screen and (min-width: 320px) {
    .project-header {
        gap: 25px;
    }
}

@media only screen and (min-width: 375px) {
    .project-header {
        gap: 50px;
    }
}

@media only screen and (min-width: 450px) {
    .project-header {
        gap: 100px;
    }
}

@media only screen and (min-width: 600px) {
    .project-header {
        gap: 0;
    }
    .project-header h1 {
        font-size: 50px;
    }
}

@media only screen and (min-width: 1160px) {
    .feed {
        flex-direction: row;
    }
}