.About {
    padding: 30px 10px 10px 10px;

}

.content{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
}

.About h2 {
    font-size: 50px;
    margin: 0 0 15px 0;
    text-align: center;
    text-shadow: 0px 2px 2px #00000040;
}

.skills{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 15px 0;
}

.skills p{
    margin: 0 !important;
    padding: 5px 10px;
    font-size: 16px !important;
    font-weight: 500;
    background-color: #ffffffda;
    border-radius: 20px;
    box-shadow: 0px 2px 2px #00000040;
}

.profile-card {
    width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 30px 20px 20px 20px;
    background-color: #ffffffda;
    gap: 20px;
    border-radius: 15px;
    box-shadow: 0px 2px 2px #00000040;

}

.headshot {
    width: 225px;
    object-fit: cover;
    border-radius: 10px;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 20px;
}

.info p {
    font-size: 20px;
}

.bio{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bio p {
    opacity: 100%;
    line-height: 1.4;
    text-align: justify;
    margin: 20px;
}

.bio-segment{
    width: 100%;
    margin: 5px 0!important;
    padding: 0;
    text-shadow: 0px 1px 1px #00000040;
}

.quick-facts {
    width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffffda;
    padding: 5px;
    border-radius: 15px;
    box-shadow: 0px 2px 2px #00000040;
    margin: 0;
    animation: rise 1s forwards;
}

.quick-facts>div {
    height: 50px;

    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.quick-facts>div img {
    height: 30px;
    opacity: 50%;
}

.quick-facts>div p {
    font-size: 20px;
    font-weight: bold;
    margin: auto 0;
}

.fact-one{
    animation: appear .5s forwards 1s;
}

.fact-two{
    animation: appear .25s forwards 1.25s;
}

.fact-three{
    animation: appear .5s forwards 1.5s;
}

@media only screen and (min-width: 375px) {

    .profile-card {
        width: 325px;
    }
    
    .headshot {
        width: 275px;
    }

    .quick-facts {
        width: 325px;
    }
}

@media only screen and (min-width: 965px) {

    .content {
        height: auto;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 40px;
        padding: 0 20px 20px 20px;
    }

    .About h2 {
        text-align: start;
        margin: 0 0 0 40px;
    }

    .info {
        align-items: flex-start;
    }

    .quick-facts {
        width: 100%;
        flex-direction: row;
    }

    .facts {
        gap: 10px;
    }

    .facts p {
        font-size: 18px;
    }

    .facts img {
        height: 20px;
    }

    .bio p {
        opacity: 0;
        animation: appear .5s forwards .75s;
        margin: 10px 0 20px 0;
    }

    .profile-card {
        margin: 20px 0px;
    }

}

@media only screen and (min-width: 1080px) {
    .facts p {
        font-size: 20px;
    }

    .facts img {
        height: 30px;
    }
}

@media only screen and (min-width: 1180px) {
    .facts p {
        font-size: 24px;
    }
}