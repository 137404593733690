@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.Error {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

.Error h2 {
    width: 400px;
    font-size: 40px;
    text-align: center;
    margin: 15px;
    text-shadow: 0px 1px 1px #00000040;
}

.Error h3 {
    margin: 0 0 0 30px;
    font-weight: 300;
    font-size: 20px;
    font-weight: 300;
}

.Error h4 {
    font-size: 30px;
    margin: 0;
    font-weight: 600;
}

.Error a:visited {
    color: #5e81ff;
}


.fake-search-header {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 10px;
    border-bottom: 4px solid #c2d2ef;
}

.fake-search-bar-error {
    display: flex;
    background-color: #ffffffda;
    width: 90%;
    height: 70px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 2px #00000040;
    margin-bottom: 20px;
}

.fake-search-bar-error img {
    margin: 0 30px 0 0;
}

.fake-search-error {
    width: 20px;
    height: 20px;
    margin: 0;
    animation: rotate 1s forwards .5s;
    opacity: 40%;
}

.fake-search-body {
    padding: 40px 60px;
}

.page-not-found {
    font-weight: 300;
    text-shadow: 0px 1px 1px #00000040;
}

.page-not-found p {
    font-size: 18px;
}

.ErrorFiller {
    width: auto;
    height: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    margin: 50px 0;
}

.fake-link {
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;
    background-color: #a3b7ff;
}

.fake-description {
    grid-column: 1 / span 4;
    grid-row: 2 / span 1;
    background-color: #86cdff;
}

@media only screen and (min-width: 530px) {
    .Error h1 {
        width: 500px;
        font-size: 26px;
        margin: auto;
    }

    .Error h3 {
        font-size: 24px;
    }

    .ErrorFiller {
        width: 400px;
        height: 50px;
    }

    .fake-search-header {
        height: 110px;
        flex-direction: row;
    }

    .fake-search-bar-error {
        margin-bottom: 0px;
    }
}

@media only screen and (min-width: 500px) {
    .Error h2 {
        font-size: 44px;
    }

    .page-not-found p {
        font-size: 22px;
    }
}

@media only screen and (min-width: 620px) {
    .Error h1 {
        font-size: 40px;
        width: 550px;
    }

    .Error h3 {
        font-size: 30px;
    }

    .fake-search-bar-error {
        width: 80%;

    }

    .fake-search-error {
        width: 30px;
        height: 30px;
    }
}

@media only screen and (min-width: 780px) {
    .Error h1 {
        font-size: 50px;
    }

    .Error h3 {
        font-size: 40px;
    }

    .fake-search-error {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (min-width: 900px) {
    .fake-search-bar-error {
        width: 90%;
    }
}