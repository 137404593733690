.App{
  width: 100vw;
  height: 100vh;
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
  display: grid;
  grid-template-columns: repeat(50,1fr);
  grid-template-rows: repeat(50,1fr);
}

.window {
  grid-column: 2 / span 48;
  grid-row: 2 / span 47;
  display: grid;
  display: flex;
  flex-direction: column;
  animation: scale-y .25s forwards;
  box-shadow: 5px 5px 10px #00000060;
  border-radius: 15px;
}

a {
  text-decoration: none;

}

a:hover {
  text-decoration: underline;

}

button:hover,
a:hover {
  cursor: pointer;
}

main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  background-image: linear-gradient(135deg, #ffffff90, #ffffffac, #ffffff90);
  overflow-y: scroll;
  border-radius: 0 0 15px 15px;
}


.purple {
  background-color: #5e81ff;
}

.blue {
  background-color: #5ebcff;
}

.pink {
  background-color: #eaabff;
}

.purple-text {
  color: #5e81ff;
}

.blue-text {
  color: #5ebcff;
}

.pink-text {
  color: #eaabff;
}

@keyframes rise {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes grow-width {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes scale-y {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}