@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

* {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

body {
  width: 100vw;
  height: 100vh;
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
  background-image: linear-gradient(135deg, #b6ffe5, #5ebcff, #5e81ff, #5ebcff, #b6ffe5);
  padding: 0;
  margin: 0;
}